define('ember-cli-materialize/mixins/uses-settings', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _ember['default'].Mixin.create({
    _mdSettings: computed(function () {
      return this.get('container').lookup('service:materialize-settings');
    })
  });
});