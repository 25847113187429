define('ember-credit-cards/components/input-credit-card-zipcode', ['exports', 'ember-new-computed', 'ember', 'ember-credit-cards/utils/has-text-selected', 'ember-credit-cards/utils/formatters', 'ember-credit-cards/utils/is-digit-keypress'], function (exports, _emberNewComputed, _ember, _emberCreditCardsUtilsHasTextSelected, _emberCreditCardsUtilsFormatters, _emberCreditCardsUtilsIsDigitKeypress) {
  'use strict';

  var TextField = _ember['default'].TextField;

  exports['default'] = TextField.extend({
    type: 'tel',
    classNames: ['input-credit-card-zipcode'],

    keyPress: function keyPress(e) {
      var digit = String.fromCharCode(e.which);

      if (!(0, _emberCreditCardsUtilsIsDigitKeypress['default'])(e)) {
        return false;
      }

      var el = this.$();
      if ((0, _emberCreditCardsUtilsHasTextSelected['default'])(el)) {
        return true;
      }

      var value = el.val() + digit;
      return value.length <= 10;
    },

    value: (0, _emberNewComputed['default'])('zipcode', {
      get: function get() {
        return _emberCreditCardsUtilsFormatters['default'].formatZipcode(this.get('zipcode'));
      },
      set: function set(key, value) {
        this.set('zipcode', value);
        return _emberCreditCardsUtilsFormatters['default'].formatZipcode(value);
      }
    })
  });
});