define('ember-credit-cards/components/input-credit-card-number', ['exports', 'ember-new-computed', 'ember', 'ember-credit-cards/utils/has-text-selected', 'ember-credit-cards/utils/formatters', 'ember-credit-cards/utils/cards', 'ember-credit-cards/utils/is-digit-keypress'], function (exports, _emberNewComputed, _ember, _emberCreditCardsUtilsHasTextSelected, _emberCreditCardsUtilsFormatters, _emberCreditCardsUtilsCards, _emberCreditCardsUtilsIsDigitKeypress) {
  'use strict';

  var cardFromNumber = _emberCreditCardsUtilsCards['default'].fromNumber;
  var TextField = _ember['default'].TextField;

  function inputValid(value) {
    value = (value + '').replace(/\D/g, '');

    var card = cardFromNumber(value);

    if (card) {
      return value.length <= card.length[card.length.length - 1];
    } else {
      return value.length <= 16;
    }
  }

  exports['default'] = TextField.extend({
    classNames: ['input-credit-card-number'],
    placeholder: '•••• •••• •••• ••••',
    autocomplete: 'cc-number',
    type: 'tel',

    keyPress: function keyPress(e) {
      if (!(0, _emberCreditCardsUtilsIsDigitKeypress['default'])(e)) {
        return false;
      }

      var digit = String.fromCharCode(e.which);
      var el = this.$();
      if ((0, _emberCreditCardsUtilsHasTextSelected['default'])(el)) {
        return true;
      }

      var value = el.val() + digit;
      return inputValid(value);
    },

    value: (0, _emberNewComputed['default'])('number', {
      get: function get() {
        return _emberCreditCardsUtilsFormatters['default'].formatNumber(this.get('number'));
      },
      set: function set(key, value) {
        this.set('number', value);
        return _emberCreditCardsUtilsFormatters['default'].formatNumber(value);
      }
    })
  });
});