define('ember-credit-cards/components/input-credit-card-cvc', ['exports', 'ember-new-computed', 'ember', 'ember-credit-cards/utils/has-text-selected', 'ember-credit-cards/utils/is-digit-keypress', 'ember-credit-cards/utils/is-whitelist-keypress'], function (exports, _emberNewComputed, _ember, _emberCreditCardsUtilsHasTextSelected, _emberCreditCardsUtilsIsDigitKeypress, _emberCreditCardsUtilsIsWhitelistKeypress) {
  'use strict';

  var TextField = _ember['default'].TextField;

  exports['default'] = TextField.extend({
    classNames: ['input-credit-card-cvc'],
    autocomplete: 'off',
    placeholder: '•••',
    label: 'Expiration',

    keyPress: function keyPress(e) {
      var digit = String.fromCharCode(e.which);

      if (!(0, _emberCreditCardsUtilsIsDigitKeypress['default'])(e)) {
        return false;
      }

      var el = this.$();
      if ((0, _emberCreditCardsUtilsHasTextSelected['default'])(el)) {
        return true;
      }

      var value = el.val() + digit;

      return (0, _emberCreditCardsUtilsIsWhitelistKeypress['default'])(e) || value.length <= 4;
    },

    value: (0, _emberNewComputed['default'])('cvc', {
      get: function get() {
        return this.get('cvc');
      },
      set: function set(propertyName, newValue) {
        var number = newValue.replace(/\D/g, '').slice(0, 4);
        this.set('cvc', newValue);

        return number;
      }
    })
  });
});