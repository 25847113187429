define("ember-cli-materialize/templates/components/md-fixed-btns", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@1.13.10",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 5
          }
        },
        "moduleName": "modules/ember-cli-materialize/templates/components/md-fixed-btns.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("ul");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "md-btn", [], ["icon", ["subexpr", "@mut", [["get", "btnIcon", ["loc", [null, [2, 7], [2, 14]]]]], [], []], "class", ["subexpr", "@mut", [["get", "_btnClassString", ["loc", [null, [3, 8], [3, 23]]]]], [], []], "action", "fireButtonAction"], ["loc", [null, [1, 0], [4, 29]]]], ["content", "yield", ["loc", [null, [7, 2], [7, 11]]]]],
      locals: [],
      templates: []
    };
  })());
});