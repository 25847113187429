define('ember-credit-cards/components/input-credit-card-expiration', ['exports', 'ember-new-computed', 'ember', 'ember-credit-cards/utils/formatters', 'ember-credit-cards/utils/has-text-selected', 'ember-credit-cards/utils/is-digit-keypress'], function (exports, _emberNewComputed, _ember, _emberCreditCardsUtilsFormatters, _emberCreditCardsUtilsHasTextSelected, _emberCreditCardsUtilsIsDigitKeypress) {
  'use strict';

  var TextField = _ember['default'].TextField;

  function inputValid(value) {
    if (!value) {
      return false;
    }

    value = (value + '').replace(/\D/g, '');

    if (value.length > 6) {
      return false;
    }

    return true;
  }

  function parseInput(value) {
    var parts = (value + '').match(/^\D*(\d{1,2})(\D+)?(\d{1,4})?/);

    if (!parts) {
      return [];
    }

    var mon = parts[1] || '';
    var year = parts[3] || '';

    if (mon.length === 1 && mon !== '0' && mon !== '1') {
      mon = "0" + mon;
    }

    return [mon, year];
  }

  exports['default'] = TextField.extend({
    classNames: ['input-credit-card-expiration'],
    month: null,
    year: null,
    placeholder: '•• / ••',
    autocomplete: 'cc-exp',

    keyPress: function keyPress(e) {
      var digit = String.fromCharCode(e.which);

      if (!(0, _emberCreditCardsUtilsIsDigitKeypress['default'])(e)) {
        return false;
      }

      var el = this.$();

      if ((0, _emberCreditCardsUtilsHasTextSelected['default'])(el)) {
        return true;
      }

      var value = el.val() + digit;
      return inputValid(value);
    },

    value: (0, _emberNewComputed['default'])('month', 'year', {
      get: function get() {
        return _emberCreditCardsUtilsFormatters['default'].formatExpiration(this.get('month'), this.get('year'));
      },
      set: function set(key, value) {
        var parsed = parseInput(value);

        var month = parsed[0];
        var year = parsed[1];

        this.setProperties({
          month: month,
          year: year
        });

        return _emberCreditCardsUtilsFormatters['default'].formatExpiration(month, year);
      }
    })
  });
});