define('ember-credit-cards/utils/is-digit-keypress', ['exports', 'ember-credit-cards/utils/is-whitelist-keypress'], function (exports, _emberCreditCardsUtilsIsWhitelistKeypress) {
  'use strict';

  exports['default'] = isDigitKeypress;

  function isDigitKeypress(e) {
    var keyCode = e.keyCode || e.which;
    var digit = String.fromCharCode(keyCode);

    if (/^\d+$/.test(digit) || (0, _emberCreditCardsUtilsIsWhitelistKeypress['default'])(e)) {
      return true;
    }

    return false;
  }
});