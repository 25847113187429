define('ember-credit-cards/utils/is-whitelist-keypress', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = isDigitKeypress;

  var validKeyCodes = _ember['default'].A([9, 8, 46, 27, 13]);
  function isDigitKeypress(e) {
    var keyCode = e.keyCode || e.which;

    return validKeyCodes.contains(keyCode);
  }
});