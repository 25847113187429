define('ember-credit-cards/components/credit-card-form', ['exports', 'ember-new-computed', 'ember', 'ember-credit-cards/utils/validations', 'ember-credit-cards/utils/cards'], function (exports, _emberNewComputed, _ember, _emberCreditCardsUtilsValidations, _emberCreditCardsUtilsCards) {
  'use strict';

  var Component = _ember['default'].Component;
  var observer = _ember['default'].observer;

  exports['default'] = Component.extend({
    tagName: 'form',
    classNames: ['credit-card-form'],
    classNameBindings: ['isValid'],
    name: null,
    number: null,
    month: null,
    year: null,
    cvc: null,
    zipcode: null,
    zipcodeRequired: false,

    isValid: _emberNewComputed['default'].and('nameValid', 'numberValid', 'expirationValid', 'cvcValid', 'zipcodeValid'),

    becameValid: observer('isValid', function () {
      this.sendAction('on-validate', this.get('isValid'));
    }),

    nameValid: (0, _emberNewComputed['default'])('name', function () {
      var name = this.get('name');

      if (name) {
        return true;
      }

      return false;
    }),

    numberValid: (0, _emberNewComputed['default'])('number', function () {
      var number = this.get('number');

      return _emberCreditCardsUtilsValidations['default'].validateNumber(number);
    }),

    expirationValid: (0, _emberNewComputed['default'])('month', 'year', function () {
      var month = this.get('month');
      var year = this.get('year');

      return _emberCreditCardsUtilsValidations['default'].validateExpiration(month, year);
    }),

    cvcValid: (0, _emberNewComputed['default'])('cvc', 'type', function () {
      var cvc = this.get('cvc');
      var type = this.get('type');

      return _emberCreditCardsUtilsValidations['default'].validateCVC(cvc, type);
    }),

    zipcodeValid: (0, _emberNewComputed['default'])('zipcodeRequired', 'zipcode', function () {
      if (this.get('zipcodeRequired')) {
        var zip = this.get('zipcode');

        return _emberCreditCardsUtilsValidations['default'].validateZipcode(zip);
      }

      return true;
    }),

    type: (0, _emberNewComputed['default'])('number', function () {
      var number = this.get('number');
      var card = _emberCreditCardsUtilsCards['default'].fromNumber(number);

      if (card) {
        return card.type;
      }
    })
  });
});